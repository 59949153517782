import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d3a3ed5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-container" }
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = { class: "notification-result" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_IonInput = _resolveComponent("IonInput")!
  const _component_IonTextarea = _resolveComponent("IonTextarea")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { text: "" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Send custom notification")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        fullscreen: true,
        class: "ion-padding"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" This will send a notification to all users subscribed to notifications in DView. "),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode("Title (optional)")
              ]),
              _: 1
            }),
            _createVNode(_component_IonInput, {
              modelValue: _ctx.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
              placeholder: "DView v1.1.3 available"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode("Message")
              ]),
              _: 1
            }),
            _createVNode(_component_IonTextarea, {
              modelValue: _ctx.message,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message) = $event)),
              rows: "5",
              placeholder: _ctx.placeholderMessageExample
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _createVNode(_component_ion_button, {
            enabled: _ctx.message.length > 0,
            onClick: _ctx.sendNotification
          }, {
            default: _withCtx(() => [
              _createTextVNode("Send notification")
            ]),
            _: 1
          }, 8, ["enabled", "onClick"]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.notificationResult), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}